.FormMainDiv {
  background: #ffffff;
  border-radius: 26px;
  overflow: hidden;
}

.formHeadingPink {
  background: #eca8a4;
}

.formHeadingBlue {
  background: #44c7ff;
}

.formHeadingPurple {
  background: #9b00ff;
}

.videoPackageDivPink {
  background: #ffeeed;
  border: 1px solid #eca8a4;
  border-radius: 10px;
}
.videoPackageDivBlue {
  background: #daf5ff;
  border: 1px solid #44c7ff;
  border-radius: 10px;
}

.videoPackageDivPurple {
  background: #f5e6ff;
  border: 1px solid #9b00ff;
  border-radius: 10px;
}

.videoPackageDivWhite {
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 10px;
}

.backButton {
  background: transparent;
  color: #cacaca !important;
  font-weight: 700;
  border: none;
  margin-right: 5px;
}

.payButtonPink {
  background: #eca8a4;
  color: white !important;
  font-weight: 700;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 5px;
}
.payButtonBlue {
  background: #44c7ff;
  color: white !important;
  font-weight: 700;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 5px;
}
.payButtonPurple {
  background: #9b00ff;
  color: white !important;
  font-weight: 700;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 5px;
}

.payButtonPinkNotSelect {
  background: #977b7a;
  color: white !important;
  font-weight: 700;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 5px;
}
.payButtonBlueNotSelect {
  background: #467a91;
  color: white !important;
  font-weight: 700;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 5px;
}
.payButtonPurpleNotSelect {
  background: #9555c0;
  color: white !important;
  font-weight: 700;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 5px;
}

/* step 2 */

.step2Label {
  color: #787f7f;
  font-weight: 600;
  font-size: 16px;
}

.step2Input {
  border: 2px solid #e9e9e9 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  padding: 10px !important;
}

/* for input file css */

.imageUpload > input {
  display: none;
}
.imageUpload {
  width: 170px !important;
  height: 170px !important;
  overflow: hidden;
  border: 3px dashed #e9e9e9;
  border-radius: 30px;
}
.imageUploadModal > input {
  display: none;
}

/* radio button */

audio {
  min-width: 150px;
  color: #000;
  border: none !important;
}

.handPickyourContentDivPink {
  background: #ffeeed;
  border-radius: 14px;
  border: 2px dashed #eca8a4;
}

.handPickyourContentDivBlue {
  background: #daf5ff;
  border-radius: 14px;
  border: 2px dashed #44c7ff;
}

.handPickyourContentDivPurple {
  background: #f5e6ff;
  border-radius: 14px;
  border: 2px dashed #9b00ff;
}
.modalBg {
  background: #e5e5e5;
  border-radius: 18px;
}
.modalBg .modal-content {
  border-radius: 18px !important;
  border: none;
  padding: 20px;
}
.modalInput {
  border-radius: 18.2349px;
  border: 1.30249px solid #e8e8e8;
  padding: 8px;
}
.modaldoneButtonBlue {
  background: #44c7ff;
  border-radius: 18.2349px;
  border: none;
}

/* wizard */

* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

#grad1 {
  background-color: #9c27b0;
  background-image: linear-gradient(120deg, #ff4081, #81d4fa);
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#msform fieldset .form-card {
  background: white;
  border: 0 none;
  border-radius: 0px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 20px 40px 30px 40px;
  box-sizing: border-box;
  width: 94%;
  margin: 0 3% 20px 3%;
  position: relative;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
}

#msform fieldset:not(:first-of-type) {
  display: none;
}

#msform fieldset .form-card {
  text-align: left;
  color: #9e9e9e;
}

#msform input,
#msform textarea {
  padding: 0px 8px 4px 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  font-size: 16px;
  letter-spacing: 1px;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  font-weight: bold;
  border-bottom: 2px solid skyblue;
  outline-width: 0;
}

#msform .action-button {
  width: 100px;
  background: skyblue;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button:hover,
#msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px skyblue;
}

#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #616161;
}

select.list-dt {
  border: none;
  outline: 0;
  border-bottom: 1px solid #ccc;
  padding: 2px 5px 3px 5px;
  margin: 2px;
}

select.list-dt:focus {
  border-bottom: 2px solid skyblue;
}

.card {
  z-index: 0;
  border: none;
  border-radius: 0.5rem;
  position: relative;
}

.fs-title {
  font-size: 25px;
  color: #2c3e50;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: #000000;
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 25%;
  float: left;
  position: relative;
}

#progressbar #account:before {
  font-family: FontAwesome;
  content: "\f023";
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "\f007";
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: "\f09d";
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "\f00c";
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: skyblue;
}

.radio-group {
  position: relative;
  margin-bottom: 25px;
}

.radio {
  display: inline-block;
  width: 204;
  height: 104;
  border-radius: 0;
  background: lightblue;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  cursor: pointer;
  margin: 8px 2px;
}

.radio:hover {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.radio.selected {
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
}

.fit-image {
  width: 100%;
  object-fit: cover;
}

/* wizard */

.circle {
  position: relative;
  border: 2px solid #999;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  line-height: 30px;
  text-align: center;
  margin-top: 18px;
  background-color: #fff;
  z-index: 2;
  font-size: 16px;
}
.circle:first-child {
  margin-top: 0;
}
.circle:before {
  position: absolute;
  border: 1px solid #999;
  width: 0;
  height: 20px;
  display: block;
  content: "";
  left: 50%;
  z-index: 1;
  top: -20px;
  margin-left: -1px;
}
.circle:first-child:before {
  display: none;
}
/* complete  Pink*/
.circleCompletePink {
  position: relative;
  border: 2px solid #eca8a4;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  line-height: 30px;
  text-align: center;
  margin-top: 18px;
  background-color: #eca8a4;
  z-index: 2;
  font-size: 16px;
  color: #ffffff;
}
.circleCompletePink:first-child {
  margin-top: 0;
}
.circleCompletePink:before {
  position: absolute;
  border: 1px solid #eca8a4;
  width: 0;
  height: 20px;
  display: block;
  content: "";
  left: 50%;
  z-index: 1;
  top: -20px;
  margin-left: -1px;
}
.circleCompletePink:first-child:before {
  display: none;
}

/* complete  Blue*/
.circleCompleteBlue {
  position: relative;
  border: 2px solid #44c7ff;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  line-height: 30px;
  text-align: center;
  margin-top: 18px;
  background-color: #44c7ff;
  z-index: 2;
  font-size: 16px;
  color: #ffffff;
}
.circleCompleteBlue:first-child {
  margin-top: 0;
}
.circleCompleteBlue:before {
  position: absolute;
  border: 1px solid #44c7ff;
  width: 0;
  height: 20px;
  display: block;
  content: "";
  left: 50%;
  z-index: 1;
  top: -20px;
  margin-left: -1px;
}
.circleCompleteBlue:first-child:before {
  display: none;
}

/* complete  purple*/
.circleCompletePurple {
  position: relative;
  border: 2px solid #9b00ff;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  line-height: 30px;
  text-align: center;
  margin-top: 18px;
  background-color: #9b00ff;
  z-index: 2;
  font-size: 16px;
  color: #ffffff;
}
.circleCompletePurple:first-child {
  margin-top: 0;
}
.circleCompletePurple:before {
  position: absolute;
  border: 1px solid #9b00ff;
  width: 0;
  height: 20px;
  display: block;
  content: "";
  left: 50%;
  z-index: 1;
  top: -20px;
  margin-left: -1px;
}
.circleCompletePurple:first-child:before {
  display: none;
}

/* Active  Pink*/
.circleActivePink {
  position: relative;
  border: 2px solid #eca8a4;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  line-height: 30px;
  text-align: center;
  margin-top: 18px;
  background-color: transparent;
  z-index: 2;
  font-size: 16px;
  color: #eca8a4;
}
.circleActivePink:first-child {
  margin-top: 0;
}
.circleActivePink:before {
  position: absolute;
  border: 1px solid #eca8a4;
  width: 0;
  height: 20px;
  display: block;
  content: "";
  left: 50%;
  z-index: 1;
  top: -20px;
  margin-left: -1px;
}
.circleActivePink:first-child:before {
  display: none;
}

/* Active  Blue*/
.circleActiveBlue {
  position: relative;
  border: 2px solid #44c7ff;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  line-height: 30px;
  text-align: center;
  margin-top: 18px;
  background-color: transparent;
  z-index: 2;
  font-size: 16px;
  color: #44c7ff;
}
.circleActiveBlue:first-child {
  margin-top: 0;
}
.circleActiveBlue:before {
  position: absolute;
  border: 1px solid #44c7ff;
  width: 0;
  height: 20px;
  display: block;
  content: "";
  left: 50%;
  z-index: 1;
  top: -20px;
  margin-left: -1px;
}
.circleActiveBlue:first-child:before {
  display: none;
}

/* Active  purple*/
.circleActivePurple {
  position: relative;
  border: 2px solid #9b00ff;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  line-height: 30px;
  text-align: center;
  margin-top: 18px;
  background-color: transparent;
  z-index: 2;
  font-size: 16px;
  color: #9b00ff;
}
.circleActivePurple:first-child {
  margin-top: 0;
}
.circleActivePurple:before {
  position: absolute;
  border: 1px solid #9b00ff;
  width: 0;
  height: 20px;
  display: block;
  content: "";
  left: 50%;
  z-index: 1;
  top: -20px;
  margin-left: -1px;
}
.circleActivePurple:first-child:before {
  display: none;
}
.payPalLoading {
  color: #000;
}
.previePageImage {
  width: 77px;
  height: 77px;
  border: 2px solid gray;
  border-radius: 6px;
  overflow: hidden;
}
