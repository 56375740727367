.emailDivBg {
  background: #f7fbfa;
}
.myAccountNavButtonBlack {
  background: transparent;
  border: none;
  color: #343434;
  font-size: 14px;
  font-weight: 700;
  border-radius: 16px;
}

.myAccountNavButton {
  background: transparent;
  border: none;
  color: #787f7f;
  font-size: 14px;
  font-weight: 700;
  border-radius: 16px;
}

.myAccountNavButtonActive {
  background: #343434;
  border: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  border-radius: 16px;
}
.videosMainDiv {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
}
.videoDiv {
  position: relative;
  max-width: 294px;
  max-height: 298px;
  box-shadow: inset 0px 0px 28.9091px rgba(0, 0, 0, 0.05);
  border-radius: 19.2727px;
}
.videoDiv:hover .videoDownloadButton {
  position: absolute;
  bottom: 0px;
  display: block;
}
.videoDownloadButton {
  display: none;
}
.videoDiv:hover .videoDownloadText {
  position: absolute;
  display: block;
  bottom: 7px;
}
.videoDownloadText {
  display: none;
}

.videoDiv:hover .videoDownloadSettingDraf {
  position: absolute;
  display: block;
  top: 10px;
  right: 10px;
}

.videoDownloadSettingDraf {
  display: none;
}

.mediaGalleryPicDiv {
  border-radius: 14px;
  max-height: 250px;
  max-height: 150px;
  overflow: hidden;
  position: relative;
}
.picNameDiv {
  position: absolute;
  bottom: 0px;
  background: rgb(36, 141, 108, 0.6);
  width: 100%;
}
.picDownloadIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.mediaGalleryAudioDiv {
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  background: #deeee9;
  width: 199px;
}

.audioDownloadbutton {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.BlogsDiv {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 14px;
}
.blogDetailImgDiv {
  max-width: 702px;
  max-height: 366px;
}
