.profileEditModal .modal-content {
  border-radius: 30px;
}
.modal-dialog {
  border-radius: 30px;
  max-width: 450px !important;
}
.bg-grayModalInput {
  background-color: #f5f9f1 !important;
  height: 50px;
}
.editProfileUpdateButton {
  height: 50px;
  border-radius: 15px !important;
  background-color: #248d6c !important;
  border: 1px solid #248d6c !important;
}

.imageCropModal .modal-content {
  min-height: 450px !important;
  display: flex;
}

.imageCropModal .btn {
  bottom: 0;
  right: 0;
}
