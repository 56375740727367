body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mr-10 {
  margin-right: 10px;
}

/* font Size */
.font-size-50 {
  font-size: 50px;
}
.font-size-60 {
  font-size: 60px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-26 {
  font-size: 26px;
}
.font-size-28 {
  font-size: 28px;
}
.font-size-38 {
  font-size: 38px;
}
.font-size-44 {
  font-size: 44px;
}
.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}
.font-size-25 {
  font-size: 25px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-13 {
  font-size: 13px;
}

@media screen and (max-width: 500px) {
  .font-size-50 {
    font-size: 30px;
  }
  .font-size-60 {
    font-size: 40px;
  }
  .font-size-18 {
    font-size: 12px;
  }
  .font-size-16 {
    font-size: 14px !important;
  }
  .font-size-26 {
    font-size: 18px;
  }
  .font-size-28 {
    font-size: 20px;
  }
  .font-size-38 {
    font-size: 26px;
  }
  .font-size-44 {
    font-size: 34px;
  }
  .font-size-20 {
    font-size: 16px;
  }

  .font-size-22 {
    font-size: 18px;
  }
  .font-size-25 {
    font-size: 20px;
  }
  .font-size-14 {
    font-size: 12px;
  }
  .font-size-13 {
    font-size: 11px;
  }
}

.font-size-20-26 {
  font-size: 26px;
}
@media screen and (max-width: 1380px) {
  .font-size-20-26 {
    font-size: 20px;
  }
}

/* font color */
.color-purple {
  color: #7d00bd;
}

.color-lightPurple {
  color: #9b00ff;
}

.color-orange {
  color: #ffa800;
}
.color-Gray {
  color: #828282;
}

.color-DarkGray {
  color: #343434;
}
.color-black {
  color: #202020;
}
.color-darkPink {
  color: #de005a;
}
.color-lightPink {
  color: #eca8a4;
}

.bg-lightPink {
  background-color: #ffeeed;
}
.color-lightBlue {
  color: #44c7ff;
}

.color-darkGreen {
  color: #248d6c;
}
.color-white {
  color: #ffffff;
}

/* font Weight */
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700;
}

/* borderRadius */
.br-10 {
  border-radius: 10px;
  border: none;
}

/* bg-purple */
.bg-purple {
  background: #7d00bd;
  color: #ffffff;
}
.bg-lightpurple {
  background: #9b00ff;
  color: #ffffff;
}
.bg-Extralightpurple {
  background: #f5e6ff;
  /* color: #ffffff; */
}

.bg-lightblue {
  background: #daf5ff;
  /* color: #ffffff; */
}

.bg-greensky {
  background: #a2ffe7;
}
.bg-lightGreen {
  background: #d4f1ea;
}

.bg-lightGray {
  background: #f9f9f9;
}

/* position */

.position-R {
  position: relative;
}
.position-A {
  position: absolute;
}

/* BackGround */
.ourVideoPromoteBack {
  background-image: url("./Assets/images/landingpage/ourVideoPromoteBack.svg");
}
.choosePersonalizationBack {
  background-image: url("./Assets//images//landingpage/choosePersonalizationBack.svg");
}
.footerbg {
  background-image: url("./Assets/images/landingpage/footerbg.svg");
}

/* cursor */
.cursor-pointer {
  cursor: pointer;
}
body,
html {
  font-family: "quicksand", sans-serif;
}
* {
  font-family: "quicksand", sans-serif;
}

.opacity-1 {
  opacity: 1 !important;
}

.ffamilybaloo {
  font-family: "Baloo 2", cursive;
}
.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-none:hover {
  color: white;
}
.object-fit-cover {
  object-fit: cover;
}

/* custom scroll bar */
/* width */
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey !important;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px !important;
}

.recordingbox {
  min-width: 40px !important;
  min-height: 40px !important;
  max-width: 40px !important;
  max-height: 40px !important;
  background: #cacaca !important;
  border-radius: 4px;
}

.cropContainer {
  position: "relative";
  width: "100%";
  height: 200;
  background: "#333";
}

.controls {
  padding: 16;
  display: "flex";
  flex-direction: "column";
  align-items: "stretch";
}
