.loginContainer {
  max-width: 450px;
  background-color: #ffffff;
  border-radius: 26px;
  margin: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.loginInput {
  background: #deeee9;
  border: none;
  border-radius: 7px;
  height: 40px;
  position: relative;
  padding-left: 40px;
}
.inputIcon {
  position: absolute;
  z-index: 10;
  padding: 10px;
}
.loginButton {
  background: #248d6c;
  color: #ffffff;
  border: none;
  font-size: 18px;
  font-weight: 700;
  padding: 7px 40px;
  border-radius: 5px;
}
