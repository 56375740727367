.logoDiv {
  width: 340px;
}

@media screen and (max-width: 500px) {
  .logoDiv {
    width: 240px;
  }
}
/* nav bar */
/* Home Green HR */

.navbar .greenHr {
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: 16px;
  color: #9bca2b !important;
  font-weight: 600;
}

.navbar .greenHr:after {
  content: "";
  position: absolute;
  background-color: #9bca2b;
  height: 3px;
  width: 0%;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
  transition: 0.35s ease;
}

/* About Blue HR */
.navbar .blueHr {
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: 16px;
  color: #7349f8 !important;
  font-weight: 600;
}
.navbar .blueHr:after {
  content: "";
  position: absolute;
  background-color: #7349f8;
  height: 3px;
  width: 0%;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
  transition: 0.35s ease;
}

/* Contact sky Blue HR */
.navbar .skyBlueHr {
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: 16px;
  color: #00d6e0 !important;
  font-weight: 600;
}
.navbar .skyBlueHr:after {
  content: "";
  position: absolute;
  background-color: #00d6e0;
  height: 3px;
  width: 0%;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
  transition: 0.35s ease;
}

/* orangePackages */
.navbar .orangePackages {
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: 16px;
  color: #f9ab00 !important;
  font-weight: 600;
}

.navbar .orangePackagesLittle {
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: 16px;
  color: #eca8a4 !important;
  font-weight: 600;
}

.navbar .orangePackagesVarsity {
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: 16px;
  color: #44c7ff !important;
  font-weight: 600;
}

.navbar .orangePackagesUpper {
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: 16px;
  color: #9b00ff !important;
  font-weight: 600;
}

.navbar a:hover:after {
  width: 100%;
}
.navbar .orangePackages:hover:after {
  width: 0px !important;
}
.myAccountMargin {
  margin-left: 70px;
}
.myAccountMargin {
  margin-left: 70px;
}
@media screen and (max-width: 991px) {
  .myAccountMargin {
    margin-left: 0px;
  }
}

.navbar .getStartedGreen {
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  color: #248d6c !important;
  font-weight: 600;
  border: 2px solid #248d6c;
  border-radius: 10px;
  margin-top: 6px;
}
.navbar .getStartedGreen:hover {
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  color: #ffff !important;
  font-weight: 600;
  background: #248d6c;
  border: 2px solid #248d6c;
  border-radius: 10px;
  margin-top: 6px;
}
.navbar .getStartedbgGreen {
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  color: #ffff !important;
  font-weight: 600;
  background: #248d6c;
  border: 2px solid #248d6c;
  border-radius: 10px;
  margin-top: 6px;
}

.navDrop-pink {
  color: #eca8a4 !important;
}
.navDrop-blue {
  color: #44c7ff !important;
}
.navDrop-purple {
  color: #9b00ff !important;
}

.navDrop-pink:hover {
  background-color: #ffeeed !important;
  color: #eca8a4 !important;
}
.navDrop-blue:hover {
  background-color: #daf5ff !important;
  color: #44c7ff !important;
}
.navDrop-purple:hover {
  background-color: #f5e6ff !important;
  color: #9b00ff !important;
}

.navbar .MyAccount {
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: 16px;
  color: #248d6c !important;
  font-weight: 600;
}

.navbar .MyAccount:hover:after {
  width: 0px !important;
}

.aboutActive {
  background-color: turquoise;
  height: 1000px !important;
}

.navbar-light .navbar-toggler {
  color: white !important;
}

.hide-child {
  height: 0px;
  overflow: hidden;
  transition: height 0.4s;
}
.d-child-159 {
  height: 159px;
  transition: height 0.4s;
  overflow: hidden;
}

.d-child {
  height: 84px;
  transition: height 0.4s;
  overflow: hidden;
}
