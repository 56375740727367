/* top banner */
.topbannerImg {
  width: 100%;
  object-fit: cover;
}

.textPositionRight {
  right: 3%;
}

@media screen and (max-width: 1300px) {
  .textPositionRight {
    right: -5%;
  }
}

@media screen and (max-width: 1150px) {
  .topBannerBackground {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    );
  }
}

@media screen and (max-width: 995px) {
  .topbannerImg {
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }
}

/* slider */

.sliderCardPink {
  border-radius: 20px;
  box-shadow: inset 0px 0px 30px rgba(0, 0, 0, 0.05);
  border: 1px solid #ffeeed;
  background: linear-gradient(180deg, #ffeeed 0%, #ffffff 43.75%);
  max-width: 361px;
}
.sliderCardBlue {
  border-radius: 20px;
  box-shadow: inset 0px 0px 30px rgba(0, 0, 0, 0.05);
  border: 1px solid #ffeeed;
  background: linear-gradient(180deg, #daf5ff 0%, #ffffff 43.75%);
  max-width: 361px;
}
.sliderCardPurple {
  border-radius: 20px;
  box-shadow: inset 0px 0px 30px rgba(0, 0, 0, 0.05);
  border: 1px solid #ffeeed;
  background: linear-gradient(180deg, #f5e6ff 0%, #ffffff 43.75%);
  max-width: 361px;
}

.sliderOptionPink {
  color: #eca8a4;
  font-weight: bold;
  font-size: 25px;
}

.sliderOptionBlue {
  color: #44c7ff;
  font-weight: bold;
  font-size: 25px;
}

.sliderOptionPurple {
  color: #9b00ff;
  font-weight: bold;
  font-size: 25px;
}

.sliderExplorePink {
  color: #eca8a4;
  font-weight: bold;
  font-size: 16px;
}

.sliderExploreBlue {
  color: #44c7ff;
  font-weight: bold;
  font-size: 16px;
}

.sliderExplorePurple {
  color: #9b00ff;
  font-weight: bold;
  font-size: 16px;
}

.slickListItem {
  padding-left: 15px !important;
  line-height: 20px;
}

/* comparison Chart .............................*/

.comparisonChartDiv {
  border-radius: 18px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.supportedFeaturesBack {
  background: linear-gradient(180deg, #deeee9 0%, rgba(222, 238, 233, 0) 100%);
  border: 1px solid #deeee9;
  border-radius: 20px;
}
.littleKnowItAllBack {
  background: linear-gradient(180deg, #ffeeed 0%, #ffffff 43.75%);
  border: 1px solid #deeee9;
  border-radius: 20px;
}
.varsityBabyBack {
  background: linear-gradient(180deg, #daf5ff 0%, #ffffff 43.75%);
  border: 1px solid #deeee9;
  border-radius: 20px;
}
.varsityBabyBack2 {
  background: linear-gradient(180deg, #9b00ff2e 0%, #ffffff 43.75%);
  border: 1px solid #deeee9;
  border-radius: 20px;
}
.upperclassmenBack {
  background: linear-gradient(180deg, #f5e6ff 0%, #ffffff 43.75%);
  border: 1px solid #deeee9;
  border-radius: 20px;
}

/* our Gallery */

.galleryImg {
  width: 303px;
  height: 215px;
  border-radius: 18px;
  overflow: hidden;
}
/* card transition on hover */
.onHoverCard {
  transition: margin-top 0.3s ease-out 10ms;
}

.onHoverCard:hover {
  margin-top: 10px !important;
}

@media screen and (max-width: 1025px) {
  .onHoverCard {
    transition: unset;
  }

  .onHoverCard:hover {
    margin-top: unset !important;
  }
}

/* tootltip */

.tootltip {
  position: relative;
}
.tootltipText {
  position: absolute;
  top: -190px;
  display: none;
  background: rgba(255, 255, 255, 0.85);
  color: #202020;
  width: 260px;
  left: -60px;
}
.tootltipText ul {
  padding: 10px 0px 10px 20px;
  margin: 0px;
}

.tootltip:hover .tootltipText {
  display: block;
}

.slick-dots li.slick-active button::before {
  font-size: 20px;
  background-color: #ff0062 !important;
}
.slick-dots li button::before {
  font-size: 22px !important;
  border: 2px solid #ff0062;
  border-radius: 100%;

  color: transparent !important;
  opacity: 1 !important;
}
.ourVideoPromoteBack .slick-dots {
  bottom: 60px !important;
  /* bottom: 45px !important; */
}

.chooseVideo .slick-dots {
  /* bottom: 60px !important; */
  bottom: -45px !important;
}
.text-decoration-inherit {
  text-decoration-line: inherit;
}
