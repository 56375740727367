.imgBgLittile {
  background: linear-gradient(180deg, #ffeeed 0%, #ffffff 43.75%);
  border: 1.31118px solid #ffeeed;
  border-radius: 8px;
}

.imgBgVarsity {
  background: linear-gradient(180deg, #daf5ff 0%, #ffffff 43.75%);
  border: 1.31118px solid #daf5ff;
  border-radius: 8px;
}

.imgBgUpper {
  background: linear-gradient(180deg, #f5e6ff 0%, #ffffff 43.75%);
  border: 1.31118px solid #f5e6ff;
  border-radius: 8px;
}
.sidePic {
  max-width: 150px;
  max-height: 150px;
  min-height: 100px;
  min-width: 150px;
}
@media screen and (max-width: 768px) {
  .sidePic {
    max-width: unset;
    max-height: unset;
  }
}
