.packageNavBg {
  background: rgba(36, 141, 108, 0.15);
}
.cardButtonPink {
  border: 2px dashed #eca8a4;
  background: #ffeeed;
  color: #eca8a4;
}
.cardButtonPink:hover {
  border: 2px solid #eca8a4;
  background: #eca8a4;
  color: #ffeeed;
}

.cardButtonBlue {
  border: 2px dashed #44c7ff;
  background: #daf5ff;
  color: #44c7ff;
}
.cardButtonBlue:hover {
  border: 2px solid #44c7ff;
  background: #44c7ff;
  color: #daf5ff;
}

.cardButtonPurple {
  border: 2px dashed #9b00ff;
  background: #f5e6ff;
  color: #9b00ff;
}
.cardButtonPurple:hover {
  border: 2px solid #9b00ff;
  background: #9b00ff;
  color: #f5e6ff;
}
